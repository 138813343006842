<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="card">
          <div class="card-header card-header-config">
            <h4>Dados da empresa</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="emp_nome_fantasia">Nome fantasia</label>
                  <input type="text" name="emp_nome_fantasia" id="emp_nome_fantasia" v-model="parametros.emp_nome_fantasia" class="form-control">
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="emp_razao_social">Razão social</label>
                  <input type="text" name="emp_razao_social" id="emp_razao_social" v-model="parametros.emp_razao_social" class="form-control">
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="emp_cnpj">CNPJ</label>
                  <input type="text" name="emp_cnpj" id="emp_cnpj" v-model="parametros.emp_cnpj" class="form-control" v-mask="'##.###.###/####-##'">
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="cep">CEP</label>
                  <input v-model="cep" type="text" class="form-control" name="cep" id="cep" placeholder="99.999-999" v-mask="'#####-###'">
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="emp_logradouro">Logradouro</label>
                  <input v-model="parametros.emp_logradouro" type="text" class="form-control" name="emp_logradouro" id="emp_logradouro">
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="emp_numero">Número</label>
                  <input v-model="parametros.emp_numero" type="text" ref="emp_numero" class="form-control" name="emp_numero" id="emp_numero">
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="emp_complemento">Complemento</label>
                  <input v-model="parametros.emp_complemento" type="text" class="form-control" name="emp_complemento" id="emp_complemento">
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="emp_bairro">Bairro</label>
                  <input v-model="parametros.emp_bairro" type="text" class="form-control" name="emp_bairro" id="emp_bairro">
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="emp_cidade">Cidade</label>
                  <input v-model="parametros.emp_cidade" type="text" class="form-control" name="emp_cidade" id="emp_cidade">
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="emp_estado">Estado</label>
                  <input v-model="parametros.emp_estado" type="text" class="form-control" name="emp_estado" id="emp_estado" v-mask="'AA'">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-5">
        <button type="button" @click="salvar" class="btn btn-primary float-right">SALVAR</button>
      </div>
    </div>

  </div>
</template>

<script>
import configuracoesRepository from './../../services/api/configuracoesRepository'
import {GetAddress} from './../../services/cep.service'
export default {
  name: 'parametros',
  data () {
    return {
      parametros: {},
      cep: ''
    }
  },
  created () {
    this.buscarParametros()
  },
  watch: {
    cep: {
      handler () {
        if (this.cep && this.cep !== null && this.cep.length == 9) {
					this.buscaCep()
				}
      }
    }
	},
  methods: {
    buscaCep: function () {
			GetAddress(this.cep).then(endereco => {
				this.parametros.emp_cep = this.cep
				this.parametros.emp_logradouro = endereco.logradouro
				this.parametros.emp_bairro = endereco.bairro
				this.parametros.emp_cidade = endereco.cidade
				this.parametros.emp_estado = endereco.estado
				this.$refs.emp_numero.focus()
				// resolve, mas nao deveria precisar
				this.$forceUpdate()
			})
		},
    buscarParametros () {
      configuracoesRepository.buscarParametros(this.parametros).then(response => {
        if (response.data['success']) {
          this.parametros = response.data['data']
        }
      })
    },
    salvar () {
      /*configuracoesRepository.salvarParametros(this.parametros).then(response => {
        console.log(response)
      })*/
      configuracoesRepository.salvarParametros(this.parametros).then(response => {
        if (response.data['success']) {
          this.$swal({
            icon: 'success',
            text: 'Parâmetros atualizados com sucesso!'
          })
        } else {
          this.$swal({
            icon: 'error',
            text: 'Não foi possível realizar a atualização!'
          })
        }
      })
    }
  }
}
</script>

<style >
  .card-header-config {
    padding: .2rem 1rem;
  }
</style>