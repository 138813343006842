import Repository from './repository'

const resource = '/v1/configuracoes'

export default {
  buscaEstadoCivil () {
    return Repository.get(`${resource}/estadoscivis`)
  },
  salvarParametros (parametros) {
    return Repository.post(`${resource}/parametros`, parametros)
  },
  buscarParametros () {
    return Repository.get(`${resource}/parametros`)
  }
}
